<template>
  <div class="base-select">
    <label :for="name">{{ label }}</label>
    <select :name="name" v-model="value">
      <option v-for="(option, index) in options" :value="option.value" :key="index">{{ option.label }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: { // the selected value
      type: [String, Number],
      default: ''
    },
    options: { // the array of select options
      type: Array,
      default: () => []
    },
    label: { // the label for the select
      type: String,
      default: ''
    },
    name: { // the name attribute for the select
      type: String,
      default: ''
    }
  },
  watch: {
    value(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style scoped>
/* Add your select styles here */
.base-select label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.base-select select {
  width: 100%;
  padding: 0.45rem;
  font-size: 0.9rem;
  border: 1px solid #2b3553;
  border-radius: 0.25rem;
  background-color: #1d253b;
  color: #fff
}
</style>
